/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { DAILY_TRIGGER, SALLIE_TRIGGER, SAVE_AND_CONTINUE, TRIGGER_SELECTOR, TRIGGER_LIST, NONE } from "../../constants/OnboardingTranslation";

import { useHistory, useLocation } from "react-router-dom";

import { Row, Col, Form } from "react-bootstrap";

import { getTBISymptoms, postNewResultingFactors, postRegisteredTrigger } from "../../api/TBIRequests";

import useListSelector from "../../lib/useListSelector";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import MedicalListSelector from "../MedicalListSelector";
import Sallie from "../Sallie";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";
import customizeMessages from "../../lib/customizeMessages";

const SelectSocialDeterminants = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const [loading, setLoading] = useState(true);
  const [returnedData, setReturnedData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [noTrigger, setNoTrigger] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });


  const categories = ["wellness", "travel", "screen time", "environmental", "Dietary", "situational"];

  const colors = {
    wellness: "#dab7ec",
    travel: "#ffdadb",
    "screen time": "#ffdcaf",
    environmental: "#fff3c4",
    Dietary: "#cef3c4",
    situational: "#c1fdf7",
    Other: "#f4f3ee",
  };

  const createItemListFromRes = (res) => {
    const itemList = categories.map((category) => {
      const items = res.data
        .filter((item) => item.subcategory === category)
        .map((item) => item.factor);
      return { category, items };
    });
    itemList.push({ category: "Other", items: [] });
    return itemList;
  };

  useEffect(() => {
    location.state?.fromPatientControl
      ? setOnboardingPercent(90)
      : setOnboardingPercent(60);
    getTBISymptoms()
      .then((res) => {
        const itemList = createItemListFromRes(res);
        const translatedItemList = itemList.map(itemList => {
          const { category, items } = itemList;
          // Translate each item in the items array
          const translatedItems = items.map(item =>
            TRIGGER_LIST[language][category][item]
          );
          return { category, items: translatedItems };
        });

        setReturnedData(translatedItemList);
        initialize(itemList);
        setLoading(false);
      })
      .catch(() => history.push("/oops"));
    // if we include initialize in the deps we get an infinite loop
    // TODO: Extract logic of init into outer function to use dep arr correctly
  }, [language]);

  useEffect(() => {
    for (let category in selectedData) {
      if (selectedData[category].includes("None") || selectedData[category].includes("Ninguno")) {
        const selectedArrayWithoutNone = [...selectedData[category]]
        selectedArrayWithoutNone.splice(selectedArrayWithoutNone.indexOf(NONE[language]));
        selectedArrayWithoutNone.forEach((item) => {
          toggleFromSelectedList(item, selectedArrayWithoutNone, category);
        })
      }
    }
  }, [selectedData]);

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        factor: item,
        category: "SDOH",
        subcategory: category,
      }));
    });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const processedData = processDataForSending();
    if (processedData.length === 0) {
      setSubmitting(false);
      setNoTrigger(true);
      return;
    } else {

      postRegisteredTrigger({
        patientId: patient.patientId,
        data: processedData,
      });

      postNewResultingFactors({
        patientId: patient.patientId,
        data: processedData,
      })
        .then(() => {
          // location.state.fromPatientControl
          //   ? history.push("/patientControl")
          history.push(
            "/selectTherapies", {
            fromPatientControl: location.state?.fromPatientControl,
          }
            // Removed due to last minute change removing 'select top trigger' page
            // Kept around as comment incase we decide to change back
            // , { forwardData: { selectedData, processedData, dateOfTBI },}
          );
        })
        .catch(() => history.push("/oops"));
    }
  };

  const getPossibleMessages = ({ }) => ({
    mainHeader: {
      Stroke: DAILY_TRIGGER[language].header,
      tbiPatient: DAILY_TRIGGER[language].header,
      caregiver: DAILY_TRIGGER[language].header,
    },
    sallieText: {
      Stroke: SALLIE_TRIGGER[language].patient,
      tbiPatient: SALLIE_TRIGGER[language].patient,
      caregiver: SALLIE_TRIGGER[language].caregiver,
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  const messages = {
    wellness: TRIGGER_SELECTOR[language].messages.wellness,
    travel: TRIGGER_SELECTOR[language].messages.travel,
    "screen time": TRIGGER_SELECTOR[language].messages.screen_time,
    environmental: TRIGGER_SELECTOR[language].messages.environmental,
    Dietary: TRIGGER_SELECTOR[language].messages.dietary,
    situational: TRIGGER_SELECTOR[language].messages.situational,
    Other: TRIGGER_SELECTOR[language].messages.other,
  };

  const tabs = {
    wellness: TRIGGER_SELECTOR[language].tabs.wellness,
    travel: TRIGGER_SELECTOR[language].tabs.travel,
    "screen time": TRIGGER_SELECTOR[language].tabs.screen_time,
    environmental: TRIGGER_SELECTOR[language].tabs.environmental,
    Dietary: TRIGGER_SELECTOR[language].tabs.dietary,
    situational: TRIGGER_SELECTOR[language].tabs.situational,
    Other: TRIGGER_SELECTOR[language].tabs.other,
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <OnboardingStepHeader text={getMessage("mainHeader")} />

          <Sallie text={getMessage("sallieText")} />
          {/* {returnedData.map((itemList, i) => (

            <TriggerListSelecter
              key={itemList.category}
              category={itemList.category}
              message={messages[itemList.category]}
              list={itemList.items}
              index={i}
              selectedList={selectedData[itemList.category]}
              toggleFromSelectedList={toggleFromSelectedList}
              selectOne={switchSingleSelection}
              addOther={addOther}
              noOther={false}
            />
          ))} */}

          <Row className="justify-content-between" style={{ marginTop: "1rem" }}>
            <Col
              sm={8}
            >
              <h5 style={{ textAlign: "center" }}>{TRIGGER_SELECTOR[language].category_header}</h5>
            </Col>
            <Col
              sm={4}
            >
              <h5 style={{ textAlign: "center" }} >{TRIGGER_SELECTOR[language].list_header}</h5>
            </Col>
          </Row>
          {/* <Col
            sm={8}
          ><h6 style={{ textAlign: "start" }} >{user.userType != "patient" ? `What did the patient do today?` : `What did you do today?`}</h6>
          </Col> */}
          <Tab.Container id="left-tabs-example" defaultActiveKey="wellness">
            <Row style={{ marginTop: "0.5rem" }}>
              <Col sm={3} >
                <Nav fill justify variant="tabs" className="flex-column">
                  {returnedData.map((itemList, i) => (
                    <Nav.Item
                    >
                      <Nav.Link eventKey={itemList.category}
                        style={{
                          background: `${colors[itemList.category]}`,
                          border: '1px solid #106995',
                        }}>{tabs[itemList.category]}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={5}>
                <Tab.Content>
                  {returnedData.map((itemList, i) => (
                    <Tab.Pane eventKey={itemList.category}
                    >
                      <MedicalListSelector
                        key={itemList.category}
                        patient={patient}
                        category={itemList.category}
                        message={messages[itemList.category]}
                        list={itemList.items}
                        index={i}
                        selectedList={selectedData[itemList.category]}
                        toggleFromSelectedList={toggleFromSelectedList}
                        selectOne={switchSingleSelection}
                        addOther={addOther}
                        noOther={false}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
              <Col sm={4}>
                <div className="justify-content-left" style={{
                  height: "30rem",
                  overflow: 'scroll',
                  color: 'black',
                  border: '1px solid #106995',
                  textAlign: "left",
                  background: "#fff",
                  padding: "2rem",
                  borderRadius: "5px",
                  margin: ".25rem 0",
                }}>
                  {Object.entries(selectedData).flatMap(([category, itemList]) => {
                    return itemList.map((item) => (
                      <div style={{ margin: '0.5rem 0' }}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>icon/action/check_ccircle_24px</title>
                          <defs>
                            <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="path-1"></path>
                          </defs>
                          <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <mask id="mask-2" fill="white">
                              <use xlinkHref="#path-1"></use>
                            </mask>
                            <use id="-↳Color" fill={colors[category]} xlinkHref="#path-1"></use>
                          </g>
                        </svg>
                        {" "}
                        {item}
                      </div>));
                  })}
                </div>
              </Col>
            </Row>
          </Tab.Container>

          <Row>
            <Col>
              {/* {hasDuplicates && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    Please only select unique symptoms
                  </Form.Control.Feedback>
                )} */}
              {noTrigger && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  {TRIGGER_SELECTOR[language].error}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col>
              <SubmitButton disabled={submitting} onClick={handleSubmit}>
                {SAVE_AND_CONTINUE[language]} &gt;
              </SubmitButton>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SelectSocialDeterminants;
