/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { THERAPIES, THERAPY_SELECTOR, SAVE_AND_CONTINUE, THERAPY_LIST, NONE } from "../../constants/OnboardingTranslation";

import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";

import { getTherapiesList, postPreviousTherapies } from "../../api/TBIRequests";

import useListSelector from "../../lib/useListSelector";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import MedicalListSelector from "../MedicalListSelector";
import Sallie from "../Sallie";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";
// import hasDuplicatesAcrossCategories from "../../lib/hasDuplicatesAcrossCategories";
import customizeMessages from "../../lib/customizeMessages";

const SelectTherapies = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const [loading, setLoading] = useState(true);
  const [returnedData, setReturnedData] = useState(["menstrual cycle", 'xvcxzvxzv']);
  const [submitting, setSubmitting] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const categories = ["Alternative", "Applied", "Chiropractic", "Vision", "Mental Health"];

  const colors = {
    Alternative: "#dab7ec",
    Applied: "#ffdadb",
    Chiropractic: "#ffdcaf",
    Vision: "#fff3c4",
    "Mental Health": "#c1fdf7",
    Other: "#f4f3ee",
  };

  const createItemListFromRes = (res) => {
    const itemList = categories.map((category) => {
      const items = res.data
        .filter((item) => item.category === category)
        .map((item) => item.therapies);
      return { category, items };
    });
    itemList.push({ category: "Other", items: [] });
    return itemList;
  };

  useEffect(() => {
    location.state?.fromPatientControl && user.userType != "patient"
      ? setOnboardingPercent(100)
      : setOnboardingPercent(70);
    getTherapiesList()
      .then((res) => {
        const itemList = createItemListFromRes(res);
        // for (let i = 0; i < itemList.length - 1; i++) {
        //   itemList[i].items.push("None");
        // }

        const translatedItemList = itemList.map(itemList => {
          const { category, items } = itemList;
          // Translate each item in the items array
          const translatedItems = items.map(item =>
            THERAPY_LIST[language][category][item]
          );
          return { category, items: translatedItems };
        });

        setReturnedData(translatedItemList);
        initialize(itemList);
        setLoading(false);
      })

      .catch(() => history.push("/oops"));
    // if we include initialize in the deps we get an infinite loop
    // TODO: Extract logic of init into outer function to use dep arr correctly
  }, [language]);

  // clears selected data from a category if none is selected
  useEffect(() => {
    for (let category in selectedData) {
      if (selectedData[category].includes("None") || selectedData[category].includes("Ninguno")) {
        const selectedArrayWithoutNone = [...selectedData[category]]
        selectedArrayWithoutNone.splice(selectedArrayWithoutNone.indexOf(NONE[language]));
        selectedArrayWithoutNone.forEach((item) => {
          toggleFromSelectedList(item, selectedArrayWithoutNone, category);
        })
      }
    }
  }, [selectedData]);

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        therapies: item,
      }));
    });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const processedData = processDataForSending();
    if (processedData.length === 0) {
      location.state?.fromPatientControl && user.userType != "patient"// if this is a patient user, quit registration too early and pick up where they left, they still need to fill in /notificationPrefs
        ? history.push("/patientControl")
        : history.push(
          "/notificationPrefs"
        );
    }
    else {
      postPreviousTherapies({
        patientId: patient.patientId,
        data: processedData,
      })
        .then(() => {
          location.state?.fromPatientControl && user.userType != "patient"
            ? history.push("/patientControl")
            : history.push(
              "/notificationPrefs"
              // Removed due to last minute change removing 'select top trigger' page
              // Kept around as comment incase we decide to change back
              // , { forwardData: { selectedData, processedData, dateOfTBI },}
            );
        })
        .catch(() => history.push("/oops"));
    }
  };

  const getPossibleMessages = ({ getPronoun }) => ({
    mainHeader: {
      Stroke: THERAPIES[language].header,
      tbiPatient: THERAPIES[language].header,
      caregiver: THERAPIES[language].header,
    },
    sallieText: {
      Stroke: THERAPIES[language].sallie,
      tbiPatient: THERAPIES[language].sallie,
      caregiver: THERAPIES[language].sallie,
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  const messages = {
    Alternative: THERAPY_SELECTOR[language].category.alternative,
    Applied: THERAPY_SELECTOR[language].category.applied,
    Chiropractic: THERAPY_SELECTOR[language].category.chiropractic,
    Vision: THERAPY_SELECTOR[language].category.vision,
    "Mental Health": THERAPY_SELECTOR[language].category.mental_health,
    Other: THERAPY_SELECTOR[language].category.other,
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <OnboardingStepHeader text={getMessage("mainHeader")} />
          <Sallie text={getMessage("sallieText")} />
          <Row className="justify-content-between" style={{ marginTop: "1rem" }}>
            <Col
              sm={8}
            >
              <h5 style={{ textAlign: "center" }}>{THERAPY_SELECTOR[language].category_header}</h5>
            </Col>
            <Col
              sm={4}
            >
              <h5 style={{ textAlign: "center" }} >{THERAPY_SELECTOR[language].list_header}</h5>
            </Col>
          </Row>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Alternative">
            <Row style={{ marginTop: "0.5rem" }}>
              <Col sm={3} >
                <Nav fill justify variant="tabs" className="flex-column">
                  {returnedData.map((itemList, i) => (
                    <Nav.Item
                    >
                      <Nav.Link eventKey={itemList.category}
                        style={{
                          background: `${colors[itemList.category]}`,
                          border: '1px solid #106995',
                        }}>{messages[itemList.category]} </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={5}>
                <Tab.Content>
                  {returnedData.map((itemList, i) => (
                    <Tab.Pane eventKey={itemList.category}
                    >
                      <MedicalListSelector
                        key={itemList.category}
                        patient={patient}
                        category={itemList.category}
                        message={messages[itemList.category]}
                        list={itemList.items}
                        index={i}
                        selectedList={selectedData[itemList.category]}
                        toggleFromSelectedList={toggleFromSelectedList}
                        selectOne={switchSingleSelection}
                        addOther={addOther}
                        noOther={false}
                      // setHeadacheParts={setHeadacheParts}
                      // headacheParts={headacheParts}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
              <Col sm={4}>
                <div className="justify-content-left" style={{
                  height: "30rem",
                  overflow: 'scroll',
                  color: 'black',
                  border: '1px solid #106995',
                  textAlign: "left",
                  background: "#fff",
                  padding: "2rem",
                  borderRadius: "5px",
                  margin: ".25rem 0",
                }}>
                  {Object.entries(selectedData).flatMap(([category, itemList]) => {
                    return itemList.map((item) => (
                      <div style={{ margin: '0.5rem 0' }}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>icon/action/check_ccircle_24px</title>
                          <defs>
                            <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="path-1"></path>
                          </defs>
                          <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <mask id="mask-2" fill="white">
                              <use xlinkHref="#path-1"></use>
                            </mask>
                            <use id="-↳Color" fill={colors[category]} xlinkHref="#path-1"></use>
                          </g>
                        </svg>
                        {" "}
                        {item}
                      </div>));
                  })}
                </div>
              </Col>
            </Row>
          </Tab.Container>

          {/*             
            <ListSelector
              // key={1}
              category={"therapies"}
              message={"therapies"}
              list={returnedData[0].items}
              index={0}
              selectedList={selectedData['therapies']}
              toggleFromSelectedList={toggleFromSelectedList}
              selectOne={switchSingleSelection}
              addOther={addOther}
            /> */}
          {/* ))} */}
          <Row>
            <Col>
              {/* {hasDuplicates && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  Please only select unique symptoms
                </Form.Control.Feedback>
              )} */}
              {/* {noTrigger && (
                <Form.Control.Feedback
                  style={{ display: "block" }}
                  type="invalid"
                >
                  Please select at least 1 trigger
                </Form.Control.Feedback>
              )} */}
            </Col>
            <Col>
              <SubmitButton disabled={submitting} onClick={handleSubmit}>
                {SAVE_AND_CONTINUE[language]} &gt;
              </SubmitButton>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SelectTherapies;
