import React, { useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { TRACK_SYMPTOM, ADDITIONAL_NOTE } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import trackerSchema from "./validations/trackerSchema";
import { yupResolver } from "@hookform/resolvers";
import format from "date-fns/format";

import { Form, Row, Col, Alert } from "react-bootstrap";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import Spinner from "react-bootstrap/Spinner";

import { postAdditionalNote } from "../../api/TBIRequests";
import { signOut } from "../../api/auth";

const AdditionalNote = ({ date }) => {
  const history = useHistory();

  const { language } = useContext(PreferredLanguageContext);
  const { patient } = useContext(PatientContext);

  const [isSaving, setIsSaving] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [show, setShow] = useState(false);

  const { register, handleSubmit, errors, reset, formState } = useForm({
    resolver: yupResolver(trackerSchema),
  });

  const onSubmit = (data) => {
    setIsSaving(true);
    data.date = date;
    postAdditionalNote({
      patientId: patient.patientId,
      data,
    })
      .then(() => {
        reset();
        setIsSaving(false);
        setShow(true);
      })
      .catch((e) => console.log(e));
  };

  const handleFinishAndLogout = async () => {
    setIsLoggingOut(true);
    await signOut();
    history.go("/");
  }

  return (
    <>
      <h6 className="d-flex justify-content-between">
        <span>
          {patient.userPatientRelationshipType == 'self'
            ? TRACK_SYMPTOM[language].patient
            : TRACK_SYMPTOM[language].non_patient[0] + patient.firstName + TRACK_SYMPTOM[language].non_patient[1]
          }
        </span>
        <span>{format(date, "LLL d, yyyy")}</span>
      </h6>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {show && !formState.isDirty ? (
          <Alert
            variant="success"
            onClose={() => {
              setShow(false);
            }}
            dismissible
          >
            {ADDITIONAL_NOTE[language].report_saved}
          </Alert>
        ) : null}
        <Col>
          <LabelledInput
            required
            label={ADDITIONAL_NOTE[language].anything_else}
            name="describeSymptom"
            errors={errors}
            inputRef={register}
            inputAs="textarea"
            rows={3}
          />
        </Col>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "-1rem 1rem 0rem 1rem"
          }}
        >
          <h6
            style={{
              borderRadius: "25px",
              backgroundColor: "#ef5866",
              color: "white",
              textAlign: "center",
              overflowWrap: "break-word",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.55rem 1.5rem",
              margin: "0"
            }}
            disabled={isLoggingOut}
            onClick={handleFinishAndLogout}
          >
            {isLoggingOut ? (
              <>
                <Spinner animation="border" size="sm" role="status" />
                {ADDITIONAL_NOTE[language].logging_out}
              </>
            ) : (
              ADDITIONAL_NOTE[language].finish_and_logout
            )}
          </h6>

          <SubmitButton type="submit" disabled={isSaving}>
            {ADDITIONAL_NOTE[language].save}
          </SubmitButton>
        </Row>
      </Form>
    </>
  );
};

export default AdditionalNote;
